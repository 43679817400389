<template>
  <div v-for="(section, index) in sections" :key="`wizard-section-${index}`">
    <wizard-menu-item
      v-if="!section?.meta?.hidden"
      :section="section"
      :valid-sections="validSections"
      :review-visited="reviewVisited"
      @click="selectSection(section)"
    ></wizard-menu-item>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, watch, UnwrapRef } from 'vue'
import { RouteLocationNormalizedLoaded, RouteRecordRaw, useRouter, Router } from 'vue-router'
import WizardMenuItem from '@/components/wizard/menu/WizardMenuItem.vue'
import { doNothing } from '@/utils'
import { WIZARD_SECTIONS } from '@/models/wizard/wizard-sections'

export default defineComponent({
  name: 'Menu',
  components: { WizardMenuItem },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    validSections: {
      type: Object,
      default: () => ({} as any),
    },
    reviewVisited: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router: Router = useRouter()
    const sections = ref<any[]>(
      router?.options?.routes?.find(route => route.name === 'Wizard')?.children || []
    )

    const findSectionIndexByPath = (path: string): number => {
      return sections.value?.findIndex(section => section?.path === path)
    }

    const getCurrentSelection = (): RouteRecordRaw | null => {
      return sections.value?.find(section => section?.meta?.selected) || null
    }

    const deselectAllSections = (routes: RouteRecordRaw[]): RouteRecordRaw[] => {
      return routes.map(
        route => ({ ...route, meta: { ...route?.meta, selected: false } } as RouteRecordRaw)
      )
    }

    const setSelectedByPath = (path: string) => {
      const newSections = deselectAllSections(sections.value)
      const index = findSectionIndexByPath(path)
      const section = newSections?.[index] || null

      if (section) {
        newSections[index] = { ...section, meta: { ...section?.meta, selected: true } }
        sections.value = newSections
      }
    }

    const selectSection = (section: UnwrapRef<RouteRecordRaw>) => {
      setSelectedByPath(section.path)
      router.push({ path: section.path }).catch(doNothing).finally()
    }

    const resetSectionValues = () => {
      sections.value =
        router?.options?.routes?.find(route => route.name === 'Wizard')?.children || []
    }

    const setVisibleSectionsForValue = (value: string) => {
      sections.value = [...sections.value]
        .filter(
          section =>
            section.path.includes(value) || section.path.includes(WIZARD_SECTIONS.REVIEW_SECTION)
        )
        .map(section => {
          return {
            ...section,
            meta: {
              ...section.meta,
              hidden:
                !section.path.includes(value) &&
                !section.path.includes(WIZARD_SECTIONS.REVIEW_SECTION),
              disabled: !section.path.includes(value),
            },
          }
        })
    }

    const setVisibleSectionsForTransactions = (transactions: string[]) => {
      resetSectionValues()

      const conditionalSections = transactions?.includes(WIZARD_SECTIONS.ELIGIBILITY_TRANSACTION)
        ? [
            WIZARD_SECTIONS.DETAILS_SECTION,
            WIZARD_SECTIONS.PATIENT_SECTION,
            WIZARD_SECTIONS.PRESCRIBER_SECTION,
            WIZARD_SECTIONS.PHARMACY_SECTION,
            WIZARD_SECTIONS.DRUG_AND_DIAGNOSIS_SECTION,
            WIZARD_SECTIONS.REVIEW_SECTION,
          ]
        : [
            WIZARD_SECTIONS.DETAILS_SECTION,
            WIZARD_SECTIONS.PATIENT_SECTION,
            WIZARD_SECTIONS.PRESCRIBER_SECTION,
            WIZARD_SECTIONS.DRUG_AND_DIAGNOSIS_SECTION,
            WIZARD_SECTIONS.REVIEW_SECTION,
          ]

      sections.value = [...sections.value]
        .filter(section =>
          conditionalSections.some(conditionalSection => section.path.includes(conditionalSection))
        )
        .map(section => {
          return {
            ...section,
            meta: {
              ...section.meta,
              hidden: !conditionalSections.some(conditionalSection =>
                section.path.includes(conditionalSection)
              ),
              disabled: false,
            },
          }
        })

      setSelectedByPath(router.currentRoute?.value?.path?.replace('/submission/', ''))
    }

    watch(router.currentRoute, (currentRoute: RouteLocationNormalizedLoaded) => {
      const path = currentRoute?.path?.replace('/submission/', '')
      setSelectedByPath(path)
    })

    watch(
      () => props.transactions,
      (transactions: any) => {
        setVisibleSectionsForTransactions(transactions)
      },
      { immediate: true }
    )

    onBeforeMount(() => {
      const currentSelection = getCurrentSelection()
      const currentPath = router.currentRoute?.value?.path?.replace('/submission/', '')
      if (currentSelection?.path && !currentPath?.includes(currentSelection.path)) {
        setSelectedByPath(currentPath)
      }

      if (currentPath?.includes(WIZARD_SECTIONS.QUESTIONNAIRE_SECTION)) {
        setVisibleSectionsForValue(WIZARD_SECTIONS.QUESTIONNAIRE_SECTION)
      } else if (currentPath?.includes(WIZARD_SECTIONS.CANCEL_SECTION)) {
        setVisibleSectionsForValue(WIZARD_SECTIONS.CANCEL_SECTION)
      } else if (currentPath?.includes(WIZARD_SECTIONS.DF_QUESTIONNAIRE_SECTION)) {
        setVisibleSectionsForValue(WIZARD_SECTIONS.DF_QUESTIONNAIRE_SECTION)
      }
    })

    return {
      sections,
      selectSection,
    }
  },
})
</script>

<style lang="scss" scoped></style>
