export enum WIZARD_SECTIONS {
  DETAILS_SECTION = 'details',
  PATIENT_SECTION = 'patient',
  PRESCRIBER_SECTION = 'prescriber',
  DRUG_AND_DIAGNOSIS_SECTION = 'drug-and-diagnosis',
  REVIEW_SECTION = 'review',
  QUESTIONNAIRE_SECTION = 'questionnaire',
  DF_QUESTIONNAIRE_SECTION = 'df-questionnaire',
  CANCEL_SECTION = 'cancel-request',
  PHARMACY_SECTION = 'pharmacy',
  ELIGIBILITY_TRANSACTION = 'eligibility-and-rtpb',
}
