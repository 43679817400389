<template>
  <div :id="'question-block-' + elementKey" class="questions-container">
    <div v-if="error" :id="'error-text-' + elementKey">
      <span class="error-text">{{ error }}</span>
    </div>
    <div
      v-if="questionValue.type === 'TEXT' && meta.type === 'TEXT'"
      :id="'text-question-' + elementKey"
      class="text-question"
    >
      <text-area-input
        element-key="text-question"
        placeholder="Enter answer here..."
        :label="questionValue.label"
        :required="meta?.value.required"
        :invalid="(!meta?.value.valid && !!meta?.value.dirty) || !!error"
        :validation="validations.text"
        @onInput="inputHandlers.text"
        @onValidate="validationHandlers.text"
      ></text-area-input>
    </div>
    <div
      v-else-if="questionValue.type === 'NUMERIC' && meta.type === 'NUMERIC'"
      :id="'numeric-question-' + elementKey"
      class="numeric-question"
    >
      <number-input
        element-key="numeric-question"
        placeholder="Enter answer here..."
        :label="questionValue.label"
        :required="meta?.value.required"
        :invalid="(!meta?.value.valid && !!meta?.value.dirty) || !!error"
        :validation="validations.numeric"
        @onInput="inputHandlers.numeric"
        @onValidate="validationHandlers.numeric"
      >
      </number-input>
    </div>
    <div
      v-else-if="questionValue.type === 'DATE' && meta.type === 'DATE'"
      :id="'date-question-' + elementKey"
      class="date-question"
    >
      <date-input
        element-key="date-question"
        :label="questionValue.label"
        :required="meta?.value.required"
        :timeRequired="isDateAndTimeRequired"
        :invalid="(!meta?.value.valid && !!meta?.value.dirty) || !!error"
        :validation="validations.date"
        @onInput="inputHandlers.date"
        @onValidate="validationHandlers.date"
      >
      </date-input>
    </div>
    <div
      v-else-if="questionValue.type === 'SELECT' && meta.type === 'SELECT'"
      :id="'select-question-' + elementKey"
      class="select-question"
    >
      <multi-select-input
        element-key="select-question"
        :label="questionValue.label"
        :options="questionValue.options"
        :optionFormatter="
          option => ({
            label: option.label,
            value: option.id,
            required: option?.explanationRequired,
          })
        "
        :value="questionValue.value"
        value-key="id"
        :reducer="
          option => ({
            id: option.value,
            label: option.label,
            required: option.required,
            explanation: '',
          })
        "
        :required="meta?.select?.required"
        :multiSelect="questionValue.multiSelect"
        :invalid="(!meta?.select?.valid && !!meta?.select?.dirty) || !!error"
        :validation="validations.select"
        @onSelection="inputHandlers.select"
        @onValidate="validationHandlers.select"
      >
      </multi-select-input>
      <explanation-block
        :items="questionValue.value"
        :meta="meta.values"
        @onInput="inputHandlers.selectDetails"
        @onValidate="validationHandlers.selectDetails"
        @onRemove="removeItemFromSelectHandler"
      ></explanation-block>
    </div>
    <div
      v-else-if="questionValue.type === 'ATTACHMENT' && meta.type === 'ATTACHMENT'"
      :id="'attachment-question-' + elementKey"
      class="attachment-question"
    >
      <div class="attachment-download-upload">
        <attachment-download
          label="The PBM has requested additional information. Please download this form ("
          labelTwo="), fill it out, and come back here to upload."
          buttonLabel="here"
          :attachmentId="questionValue.questionsAttachment || undefined"
          @onClick="attachmentDownload"
        ></attachment-download>
        <attachment-input
          element-key="attachment-question"
          :label="questionValue.label"
          :required="meta?.value.required"
          :isLoading="isLoading"
          @onUpload="attachmentUpload"
          @onValidate="validationHandlers.attachment"
        ></attachment-input>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid'
import { computed, defineComponent, onBeforeMount, PropType, ref, watch } from 'vue'
import { QuestionModels } from '@/models/questions'
import { WizardStateModels } from '@/models/store'
import { InputMeta } from '@/models/wizard'
import {
  AttachmentDownload,
  AttachmentInput,
  DateInput,
  MultiSelectInput,
  NumberInput,
  TextAreaInput,
} from '@/components/shared'
import { ExplanationBlock } from '@/components/wizard/blocks'

export default defineComponent({
  name: 'QuestionBlock',
  components: {
    AttachmentDownload,
    AttachmentInput,
    DateInput,
    ExplanationBlock,
    MultiSelectInput,
    NumberInput,
    TextAreaInput,
  },
  emits: ['on-input', 'on-meta', 'on-upload', 'on-download'],
  props: {
    elementKey: {
      type: String,
      default: () => uuid(),
    },
    question: {
      type: Object as PropType<QuestionModels.Question>,
      default: () => ({}),
    },
    error: {
      type: String,
      default: null,
    },
    meta: {
      type: Object as PropType<WizardStateModels.WizardQuestionMeta>,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const questionValue = ref<QuestionModels.Question>(props.question)
    const isDateAndTimeRequired = computed<boolean>(
      () => QuestionModels.isDateQuestion(props.question) && props.question.isDateTime
    )
    const metaValue = ref<WizardStateModels.WizardQuestionMeta>(props.meta)

    // This validates all questions have values
    const validations = {
      text: (value: string): boolean => !!value && value?.trim().length > 0,
      date: (value: string): boolean => !!value,
      numeric: (value: number | null): boolean => value !== null,
      select: (values: any[]): boolean => !!values?.length,
    }

    const attachmentDownload = (id: string) => {
      context.emit('on-download', id)
    }

    const attachmentUpload = (attachment: { name: string; data: FormData }) => {
      context.emit('on-upload', attachment)
    }

    const setQuestionValue = (value: any) => {
      questionValue.value = { ...questionValue.value, value }
    }

    const setMetaValue = (value: InputMeta, values?: InputMeta[]) => {
      if (WizardStateModels.isGeneralQuestionMeta(metaValue.value)) {
        metaValue.value = { ...metaValue.value, value: { ...metaValue.value?.value, ...value } }
      } else if (WizardStateModels.isWizardSelectQuestionMeta(metaValue.value)) {
        metaValue.value = {
          ...metaValue.value,
          select: { ...metaValue.value.select, ...value },
          values: values ? [...values] : [],
        }
      }
      context.emit('on-meta', metaValue.value)
    }

    const inputHandlers = {
      text: (value: string | null) => {
        setQuestionValue(value)
        if (WizardStateModels.isWizardTextQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.value, dirty: true })
        }
      },
      numeric: (value: string | null) => {
        setQuestionValue(value)
        if (WizardStateModels.isWizardNumericQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.value, dirty: true })
        }
      },
      date: (value: string | null) => {
        setQuestionValue({
          isDateTime: QuestionModels.isDateQuestion(props.question) && props.question.isDateTime,
          date: value,
        })
        if (WizardStateModels.isWizardDateQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.value, dirty: true })
        }
      },
      select: (values: any[]) => {
        if (
          WizardStateModels.isWizardSelectQuestionMeta(metaValue.value) &&
          QuestionModels.isSelectQuestion(questionValue.value)
        ) {
          const newValues: any = []

          for (const value of values) {
            const index = questionValue?.value?.value?.findIndex(
              questionVal => questionVal.id === value.id
            )
            if (index !== -1 && questionValue.value?.value?.[index]) {
              newValues.push(questionValue.value.value[index])
            } else {
              newValues.push(value)
            }
          }

          setQuestionValue(newValues)

          const itemMetaValues: InputMeta[] = []
          for (const value of values) {
            const metaItem = metaValue.value.values?.find(metaVal => metaVal.id === value.id)
            itemMetaValues.push({
              ...metaValue.value?.select,
              required: value.required,
              dirty: metaItem?.dirty || false,
              valid: metaItem?.valid || !value.required,
              id: value.id,
            })
          }
          setMetaValue({ ...metaValue.value?.select, dirty: true }, itemMetaValues)
        }
      },
      selectDetails: (item: { id: string; explanation: string | null }) => {
        if (
          WizardStateModels.isWizardSelectQuestionMeta(metaValue.value) &&
          QuestionModels.isSelectQuestion(questionValue.value)
        ) {
          const questionIndex = questionValue.value?.value?.findIndex(
            questionItem => questionItem.id === item.id
          )
          const metaValueIndex = metaValue.value?.values?.findIndex(
            metaItem => metaItem.id === item.id
          )

          if (questionIndex !== -1 && metaValueIndex !== -1) {
            const metaValueCopy = [...metaValue.value?.values]
            const questionsCopy = [...questionValue.value?.value]
            questionsCopy[questionIndex] = {
              ...questionsCopy[questionIndex],
              explanation: item.explanation,
            }
            setQuestionValue(questionsCopy)

            metaValueCopy[metaValueIndex] = {
              ...metaValueCopy[metaValueIndex],
              dirty: true,
              valid: !!item.explanation,
            }
            setMetaValue({ ...metaValue.value?.select }, metaValueCopy)
          }
        }
      },
    }

    const validationHandlers = {
      text: (valid: boolean) => {
        if (WizardStateModels.isWizardTextQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.value, valid })
        }
      },
      numeric: (valid: boolean) => {
        if (WizardStateModels.isWizardNumericQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.value, valid })
        }
      },
      date: (valid: boolean) => {
        if (WizardStateModels.isWizardDateQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.value, valid })
        }
      },
      select: (valid: boolean) => {
        if (WizardStateModels.isWizardSelectQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.select, valid }, metaValue.value.values || [])
        }
      },
      selectDetails: (item: { id: string; valid: boolean }) => {
        if (WizardStateModels.isWizardSelectQuestionMeta(metaValue.value)) {
          const metaItemIndex = metaValue.value?.values?.findIndex(
            metaItem => metaItem.id === item.id
          )

          if (metaItemIndex !== -1) {
            const metaValues = [...metaValue.value?.values]
            metaValues[metaItemIndex] = {
              ...metaValues[metaItemIndex],
              valid: item.valid,
            }
            setMetaValue({ ...metaValue.value?.select }, metaValues)
          }
        }
      },
      attachment: (valid: boolean) => {
        if (WizardStateModels.isWizardAttachmentQuestionMeta(metaValue.value)) {
          setMetaValue({ ...metaValue.value?.value, valid })
        }
      },
    }

    const removeItemFromSelectHandler = (id: string) => {
      if (QuestionModels.isSelectQuestion(questionValue.value)) {
        const index = questionValue.value.value?.findIndex(val => val.id === id)
        if (index !== -1 && WizardStateModels.isWizardSelectQuestionMeta(metaValue.value)) {
          const valueCopy = [...questionValue.value.value]
          valueCopy.splice(index, 1)
          questionValue.value = { ...questionValue.value, value: valueCopy }
          const valid =
            (!!questionValue.value?.value?.length &&
              !metaValue.value?.values?.some(metaVal => !metaVal.valid)) ||
            !metaValue.value?.select.dirty
          setMetaValue({ ...metaValue.value?.select, valid }, metaValue.value?.values || [])
        }
      }
    }

    watch(questionValue, value => context.emit('on-input', value))
    watch(
      () => props.meta,
      meta => {
        metaValue.value = meta
      },
      { immediate: true }
    )

    onBeforeMount(() => {
      questionValue.value = props.question
    })

    return {
      questionValue,
      inputHandlers,
      isDateAndTimeRequired,
      validations,
      validationHandlers,

      attachmentDownload,
      attachmentUpload,
      removeItemFromSelectHandler,
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/styles/input';
@import '@myndshft/color-palette/src/colors.scss';

.questions-container {
  display: flex;
  flex-direction: column;
}

.text-question,
.select-question,
.attachment-question {
  display: block;
  padding: 10px;
}

.numeric-question,
.date-question {
  width: 50%;
  display: block;
  padding: 10px;
}

.attachment-download-upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.error-text {
  width: 100%;
  padding: 0 10px;
  color: $myndshft-required-pink;
  justify-content: center;
}
</style>
