import { RouteRecordRaw } from 'vue-router'
import {
  AuthCancellationSection,
  DetailsSection,
  DrugAndDiagnosisSection,
  PatientSection,
  PayerQuestionnaireSection,
  PharamacySection,
  PrescriberSection,
  ReviewSection,
  DFPayerQuestionnaireSection,
} from '@/components/wizard/sections'
import { WIZARD_SECTIONS } from '@/models/wizard/wizard-sections'
import Features from '@/services/features'

export const wizardSections: Array<RouteRecordRaw> = [
  {
    path: WIZARD_SECTIONS.DETAILS_SECTION,
    name: 'Details',
    component: DetailsSection,
    meta: {
      selected: true,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: WIZARD_SECTIONS.PATIENT_SECTION,
    name: 'Patient',
    component: PatientSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: WIZARD_SECTIONS.PRESCRIBER_SECTION,
    name: 'Prescriber',
    component: PrescriberSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: WIZARD_SECTIONS.DRUG_AND_DIAGNOSIS_SECTION,
    name: 'Drug and Diagnosis',
    component: DrugAndDiagnosisSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: WIZARD_SECTIONS.PHARMACY_SECTION,
    name: 'Pharmacy',
    component: PharamacySection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: WIZARD_SECTIONS.QUESTIONNAIRE_SECTION,
    name: 'Question Set',
    component: PayerQuestionnaireSection,
    meta: {
      selected: false,
      valid: null,
      disabled: true,
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: WIZARD_SECTIONS.DF_QUESTIONNAIRE_SECTION,
    name: 'Questions Set',
    component: DFPayerQuestionnaireSection,
    meta: {
      selected: false,
      valid: null,
      disabled: true,
      hidden: true,
      requiresAuth: true,
    },
    beforeEnter: (_to, _from, next) => {
      Features.getFeatures().then(features => {
        if (features?.includes('isEnabledToUseDFQuestions')) {
          next()
        } else {
          next({ path: WIZARD_SECTIONS.QUESTIONNAIRE_SECTION })
        }
      })
    },
  },
  {
    path: WIZARD_SECTIONS.REVIEW_SECTION,
    name: 'Review',
    component: ReviewSection,
    meta: {
      selected: false,
      valid: null,
      disabled: false,
      requiresAuth: true,
    },
  },
  {
    path: WIZARD_SECTIONS.CANCEL_SECTION,
    name: 'Cancel Request',
    component: AuthCancellationSection,
    meta: {
      selected: false,
      valid: null,
      disabled: true,
      hidden: true,
      requiresAuth: true,
    },
  },
  {
    path: '',
    name: 'redirect-details',
    redirect: 'details',
    meta: {
      hidden: true,
    },
  },
]
