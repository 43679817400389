import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-680a23f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "error-text" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "attachment-download-upload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_area_input = _resolveComponent("text-area-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_multi_select_input = _resolveComponent("multi-select-input")!
  const _component_explanation_block = _resolveComponent("explanation-block")!
  const _component_attachment_download = _resolveComponent("attachment-download")!
  const _component_attachment_input = _resolveComponent("attachment-input")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'question-block-' + _ctx.elementKey,
    class: "questions-container"
  }, [
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: 'error-text-' + _ctx.elementKey
        }, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.error), 1)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.questionValue.type === 'TEXT' && _ctx.meta.type === 'TEXT')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          id: 'text-question-' + _ctx.elementKey,
          class: "text-question"
        }, [
          _createVNode(_component_text_area_input, {
            "element-key": "text-question",
            placeholder: "Enter answer here...",
            label: _ctx.questionValue.label,
            required: _ctx.meta?.value.required,
            invalid: (!_ctx.meta?.value.valid && !!_ctx.meta?.value.dirty) || !!_ctx.error,
            validation: _ctx.validations.text,
            onOnInput: _ctx.inputHandlers.text,
            onOnValidate: _ctx.validationHandlers.text
          }, null, 8, ["label", "required", "invalid", "validation", "onOnInput", "onOnValidate"])
        ], 8, _hoisted_4))
      : (_ctx.questionValue.type === 'NUMERIC' && _ctx.meta.type === 'NUMERIC')
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            id: 'numeric-question-' + _ctx.elementKey,
            class: "numeric-question"
          }, [
            _createVNode(_component_number_input, {
              "element-key": "numeric-question",
              placeholder: "Enter answer here...",
              label: _ctx.questionValue.label,
              required: _ctx.meta?.value.required,
              invalid: (!_ctx.meta?.value.valid && !!_ctx.meta?.value.dirty) || !!_ctx.error,
              validation: _ctx.validations.numeric,
              onOnInput: _ctx.inputHandlers.numeric,
              onOnValidate: _ctx.validationHandlers.numeric
            }, null, 8, ["label", "required", "invalid", "validation", "onOnInput", "onOnValidate"])
          ], 8, _hoisted_5))
        : (_ctx.questionValue.type === 'DATE' && _ctx.meta.type === 'DATE')
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              id: 'date-question-' + _ctx.elementKey,
              class: "date-question"
            }, [
              _createVNode(_component_date_input, {
                "element-key": "date-question",
                label: _ctx.questionValue.label,
                required: _ctx.meta?.value.required,
                timeRequired: _ctx.isDateAndTimeRequired,
                invalid: (!_ctx.meta?.value.valid && !!_ctx.meta?.value.dirty) || !!_ctx.error,
                validation: _ctx.validations.date,
                onOnInput: _ctx.inputHandlers.date,
                onOnValidate: _ctx.validationHandlers.date
              }, null, 8, ["label", "required", "timeRequired", "invalid", "validation", "onOnInput", "onOnValidate"])
            ], 8, _hoisted_6))
          : (_ctx.questionValue.type === 'SELECT' && _ctx.meta.type === 'SELECT')
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                id: 'select-question-' + _ctx.elementKey,
                class: "select-question"
              }, [
                _createVNode(_component_multi_select_input, {
                  "element-key": "select-question",
                  label: _ctx.questionValue.label,
                  options: _ctx.questionValue.options,
                  optionFormatter: 
          option => ({
            label: option.label,
            value: option.id,
            required: option?.explanationRequired,
          })
        ,
                  value: _ctx.questionValue.value,
                  "value-key": "id",
                  reducer: 
          option => ({
            id: option.value,
            label: option.label,
            required: option.required,
            explanation: '',
          })
        ,
                  required: _ctx.meta?.select?.required,
                  multiSelect: _ctx.questionValue.multiSelect,
                  invalid: (!_ctx.meta?.select?.valid && !!_ctx.meta?.select?.dirty) || !!_ctx.error,
                  validation: _ctx.validations.select,
                  onOnSelection: _ctx.inputHandlers.select,
                  onOnValidate: _ctx.validationHandlers.select
                }, null, 8, ["label", "options", "optionFormatter", "value", "reducer", "required", "multiSelect", "invalid", "validation", "onOnSelection", "onOnValidate"]),
                _createVNode(_component_explanation_block, {
                  items: _ctx.questionValue.value,
                  meta: _ctx.meta.values,
                  onOnInput: _ctx.inputHandlers.selectDetails,
                  onOnValidate: _ctx.validationHandlers.selectDetails,
                  onOnRemove: _ctx.removeItemFromSelectHandler
                }, null, 8, ["items", "meta", "onOnInput", "onOnValidate", "onOnRemove"])
              ], 8, _hoisted_7))
            : (_ctx.questionValue.type === 'ATTACHMENT' && _ctx.meta.type === 'ATTACHMENT')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 5,
                  id: 'attachment-question-' + _ctx.elementKey,
                  class: "attachment-question"
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_attachment_download, {
                      label: "The PBM has requested additional information. Please download this form (",
                      labelTwo: "), fill it out, and come back here to upload.",
                      buttonLabel: "here",
                      attachmentId: _ctx.questionValue.questionsAttachment || undefined,
                      onOnClick: _ctx.attachmentDownload
                    }, null, 8, ["attachmentId", "onOnClick"]),
                    _createVNode(_component_attachment_input, {
                      "element-key": "attachment-question",
                      label: _ctx.questionValue.label,
                      required: _ctx.meta?.value.required,
                      isLoading: _ctx.isLoading,
                      onOnUpload: _ctx.attachmentUpload,
                      onOnValidate: _ctx.validationHandlers.attachment
                    }, null, 8, ["label", "required", "isLoading", "onOnUpload", "onOnValidate"])
                  ])
                ], 8, _hoisted_8))
              : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}